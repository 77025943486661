import React from 'react'
import Layout from '../components/layout'
import { Row, Col, Card, CardBody } from 'reactstrap'

export default () => (
  <Layout>
    <Row>
      <Col>
        <Card>
          <CardBody>
            <h5 className="card-title">Dienstleistungen</h5>
            <p className="card-text">
              Dienstleistung bedeutet für uns Nähe zum Mandanten und persönliche
              Erreichbarkeit in allen fachlichen Fragen. Unser Team begleitet
              Sie mit viel Berufserfahrung und unterstützt Sie als Unternehmer
              und Steuerpflichtigem mit:
            </p>
          </CardBody>
          <ul className="">
            <li className="font-weight-bold">
              Betriebswirtschaftlicher Beratung
              <p className="font-weight-normal">
                Wir unterstützen Sie in allen Fragen von der Existenzgründung
                bis zur Unternehmensnachfolge
              </p>
            </li>
            <li className="font-weight-bold">
              Aufbau des Rechnungswesens
              <p className="font-weight-normal">
                wahlweise als Service für Sie in unserer Kanzlei oder in Ihrem
                Unternehmen
              </p>
            </li>
            <li className="font-weight-bold">
              Erstellung von Jahresabschlüssen
              <p className="font-weight-normal">
                Vom Freiberufler bis zu Großunternehmen und für alle
                Rechtsformen
              </p>
            </li>
            <li className="font-weight-bold">
              Erstellung von Lohn- und Gehaltsabrechnungen
              <p className="font-weight-normal">
                Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                Praesentium possimus quia esse aut, porro labore numquam, ipsum,
                quo assumenda fugiat expedita a tenetur dolorem odio deleniti.
                Enim ab quod, nam?
              </p>
            </li>
            <li className="font-weight-bold">
              Deklaration aller betrieblichen und persönlichen Steuern
              <p className="font-weight-normal">
                Unabhängig von der Gesellschaftsform sorgen wir dafür, dass Ihre
                Steuern korrekt deklariert werden
              </p>
            </li>
          </ul>
        </Card>
      </Col>
    </Row>
  </Layout>
)
